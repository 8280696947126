<template>
  <div>
    <div
      v-if="$can('update', 'licensesconditions')"
      class="btn-actions-content d-flex"
    >
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="cancel()"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveChanges(licensingConditionsData)"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <div v-if="!apiInCall">
      <validation-observer ref="form">
        <h6>Dados do requerente</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col
              md="2"
              class=""
            >
              <validation-provider
                v-slot="{ errors }"
                name="código"
                rules="required"
              >
                <b-form-group label="Código">
                  <b-input-group>
                    <b-form-input
                      id="codigo"
                      v-model="licensingConditionsData.licensing_conditions_code"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-group label="Nome">
                  <b-input-group>
                    <b-form-input
                      id="nome"
                      v-model="licensingConditionsData.licensing_conditions_name"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                v-slot="{ errors }"
                name="empreendimento"
                rules="required"
              >
                <b-form-group label="Empreendimento">
                  <b-input-group>
                    <b-form-select
                      id="empreendimento"
                      v-model="licensingConditionsData.project_id"
                      :options="getEnterpriseOptions"
                      :disabled="!edit"
                      @change="fetchEnterpriseData(licensingConditionsData.project_id)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group :label="enterpriseData.cnpj ? 'CNPJ' : 'CPF'">
                <b-input-group>
                  <b-form-input
                    id="cnpj"
                    v-mask="enterpriseData.cnpj ? '##.###.###/####-##' : '###.###.###-##'"
                    :value="enterpriseData ? ( enterpriseData.cnpj || enterpriseData.cpf ) : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="enterpriseData.cnpj ? 'Razão Social' : 'Nome Completo'">
                <b-input-group>
                  <b-form-input
                    id="razao-social"
                    :value="enterpriseData ? ( enterpriseData.project_name || enterpriseData.full_name ) : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-group label="Empresa Vinculada">
                <b-input-group>
                  <b-form-input
                    id="empresa-vinculada"
                    :value="enterpriseData && enterpriseData.company ? (enterpriseData.company.nome_fantasia || enterpriseData.company.full_name) : ''"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="gestor principal"
                rules="required"
              >
                <b-form-group label="Gestor Principal">
                  <b-input-group>
                    <b-form-select
                      id="gestor-principal"
                      v-model="licensingConditionsData.main_manager_id"
                      :options="usersOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <h6>Endereço</h6>
        <b-form class="mt-3">
          <b-row>

            <b-col md="2">
              <b-form-group label="CEP">
                <b-input-group>
                  <b-form-input
                    id="cep"
                    :value="enterpriseData ? enterpriseData.zip_code : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Cidade">
                <b-input-group>
                  <b-form-input
                    id="cidade"
                    :value="enterpriseData ? enterpriseData.city : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="UF">
                <b-input-group>
                  <b-form-select
                    id="uf"
                    :value="enterpriseData ? enterpriseData.state : ''"
                    :options="ufOptions"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Bairro">
                <b-input-group>
                  <b-form-input
                    id="bairro"
                    :value="enterpriseData ? enterpriseData.neighborhood : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Endereço">
                <b-input-group>
                  <b-form-input
                    id="endereco"
                    :value="enterpriseData ? enterpriseData.address : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Número / Complemento">
                <b-input-group>
                  <b-form-input
                    id="numero"
                    :value="enterpriseData ? enterpriseData.address_number : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <h6>Dados da licença</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="órgão"
                rules="required"
              >
                <b-form-group label="Órgão">
                  <b-input-group>
                    <b-form-select
                      id="orgao"
                      v-model="licensingConditionsData.agency_id"
                      :disabled="!edit"
                      :options="licensingAgencyOptions"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="tipo de licença"
                rules="required"
              >
                <b-form-group label="Tipo de Licença">
                  <b-input-group>
                    <b-form-select
                      id="tipo-licenca"
                      v-model="licensingConditionsData.license_type_id"
                      :options="licenseTypeOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="número do processo"
                rules="required"
              >
                <b-form-group label="Número do processo">
                  <b-input-group>
                    <b-form-input
                      id="numero-processo"
                      v-model="licensingConditionsData.process_number"
                      :disabled="!edit"
                      type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="número da licença"
                rules="required"
              >
                <b-form-group label="Número da Licença">
                  <b-input-group>
                    <b-form-input
                      id="numero-processo"
                      v-model="licensingConditionsData.license_number"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="2">

              <b-form-group label="Parecer Técnico">
                <b-input-group>
                  <b-form-input
                    id="parecer-tecnico"
                    v-model="licensingConditionsData.technical_advice"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="situação"
                rules="required"
              >
                <b-form-group label="Situação">
                  <b-input-group>
                    <b-form-select
                      id="situacao"
                      v-model="licensingConditionsData.situation"
                      :options="situationOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-group label="Licença Anterior">
                <b-input-group>
                  <b-form-input
                    id="licenca-anterior"
                    v-model="licensingConditionsData.previous_license"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Processo Anterior">
                <b-input-group>
                  <b-form-input
                    id="processo-anterior"
                    v-model="licensingConditionsData.previous_process"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <h6>Gestão de prazo</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="data de emissão"
                rules="required"
              >
                <b-form-group label="Data de Emissão">
                  <b-input-group>
                    <b-form-input
                      id="data-emissao"
                      v-model="licensingConditionsData.emission_date"
                      :disabled="!edit"
                      type="date"
                      @change="setday()"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="prazo de validade"
                rules="required|positive"
              >
                <b-form-group label="Prazo de Validade (em meses)">
                  <b-input-group>
                    <b-form-input
                      id="prazo-conclusao"
                      v-model.number="licensingConditionsData.expiration_deadline"
                      :disabled="!edit"
                      type="number"
                      @input="calcMonths(licensingConditionsData.emission_date, 'validate',licensingConditionsData.expiration_deadline)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data de Validade">
                <b-input-group>
                  <b-form-input
                    id="data-validade"
                    v-model="licensingConditionsData.expiration_date"
                    type="date"
                    :disabled="!edit"
                    @change="setday()"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="prazo de renovação"
                rules="required|positive"
              >
                <b-form-group label="Prazo de Renovação (em dias)">
                  <b-input-group>
                    <b-form-input
                      id="prazo-conclusao"
                      v-model.number="licensingConditionsData.renewal_period"
                      :disabled="!edit"
                      type="number"
                      @input="calcDays(licensingConditionsData.expiration_date, 'renewal',licensingConditionsData.renewal_period)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data de Renovação">
                <b-input-group>
                  <b-form-input
                    id="data-renovacao"
                    v-model="licensingConditionsData.renewal_date"
                    type="date"
                    :disabled="!edit"
                    :max="licensingConditionsData.expiration_date"
                    @change="setday(true)"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                v-b-tooltip.hover
                title="Quantidade de dias de antecedência que deseja ser avisado da renovação da licença."
                label="Alerta de Renovação"
              >
                <b-input-group>
                  <b-form-input
                    id="alerta-renovacao"
                    v-model="licensingConditionsData.renewal_alert"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <b-spinner
      v-else
      variant="primary"
      class="general-data-spinner"
    />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, BFormSelect, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formatDateTime } from '@core/utils/filter'
import moment from 'moment'
import { required } from '@/@core/utils/validations/validations'
import VSIcons from '@/assets/icons/VSIcons.vue'
import license from '@/services/license'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, BFormSelect, VSIcons, BSpinner,
  },
  filters: {
    formatDateTime,
  },
  data() {
    return {
      required,
      createLicensingConditions: false,
      companyId: null,
      licensingConditionsData: {
        licensing_conditions_code: '',
        licensing_conditions_name: '',
        main_manager_id: null,
        project_id: null,
        agency_id: null,
        process_number: null,
        license_number: null,
        license_type_id: null,
        emission_date: null,
        technical_advice: null,
        situation: '',
        previous_license: null,
        previous_process: null,
        expiration_deadline: 0,
        expiration_date: null,
        renewal_period: 0,
        renewal_date: null,
        countdown: null,
        renewal_alert: null,
        project: null,
        licensingAgency: null,
        licenseType: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      edit: 'app/getItemTableEdit',
      apiInCall: 'app/getApiInCall',
      sectorOptions: 'sector/getSectorsOptions',
      companiesOptions: 'company/getCompaniesOptions',
      ufOptions: 'app/getUfOptions',
      stepOptions: 'app/getStepOptions',
      deadlineOptions: 'app/getDeadlineOptions',
      getEnterpriseOptions: 'enterprise/getEnterpriseOptions',
      licensingAgencyOptions: 'publicAgencies/getLicensingAgencyOptions',
      getLicenseTypesOptionsByPublicAgency: 'licenseType/getLicenseTypesOptionsByPublicAgency',
      enterpriseData: 'enterprise/getEnterpriseData',
      processTypeOptions: 'app/getProcessTypeOptions',
      situationOptions: 'app/getSituationOptions',
      usersOptions: 'users/getUsersOptions',
    }),
    enterpriseQuantity() {
      return this.licensingConditionsData.projects.length
    },
    licenseTypeOptions() {
      return this.getLicenseTypesOptionsByPublicAgency(this.licensingConditionsData.agency_id)
    },
    deadlineCount: {
      get() {
        let months
        months = (new Date(this.licensingConditionsData.expiration_date).getFullYear() - new Date(this.licensingConditionsData.emission_date).getFullYear()) * 12
        months -= new Date(this.licensingConditionsData.emission_date).getMonth()
        months += new Date(this.licensingConditionsData.expiration_date).getMonth()
        return months <= 0 ? 0 : months
      },
    },
    deadlineCountExpiration: {
      get() {
        const diference = new Date(this.licensingConditionsData.expiration_date) - new Date(this.licensingConditionsData.renewal_date)
        return (diference / (60 * 60 * 24 * 1000))
      },
    },
  },
  created() {
    if (this.verifyRouteAddNewItem()) {
      this.createLicensingConditions = true
      this.setSubtitle('')
      this.setDataEnterprise({})
    } else {
      this.fetchLicensing(this.$route.params.id)
    }
    this.fetchCompanies()
    this.fetchEnterprises()
    this.fetchLicensingAgencies()
    this.fetchLincenseTypes()
    this.fetchUsers()
  },
  mounted() {
    this.setDataEnterprise({})
  },
  methods: {
    ...mapMutations({
      setEdit: 'app/SET_ITEM_TABLE_EDIT',
      setSubtitle: 'app/SET_SUBTITLE',
      setlicensingConditionsData: 'licensing/SET_LICENSING_DATA',
      setDataEnterprise: 'enterprise/SET_ENTERPRISE_DATA',
    }),
    ...mapActions({
      fetchCompanies: 'company/fetchCompanies',
      fetchEnterprises: 'enterprise/fetchEnterprises',
      fetchLicensingAgencies: 'publicAgencies/fetchLicensingAgencies',
      fetchLincenseTypes: 'licenseType/fetchLincenseTypes',
      fetchEnterpriseData: 'enterprise/fetchEnterpriseById',
      fetchUsers: 'users/fetchUsers',
    }),
    calcMonths(startDate, finalDate, newValue) {
      // eslint-disable-next-line
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setMonth(date.getMonth() + days)
        return date
      }
      const today = new Date()
      if (!startDate) {
        if (!finalDate) {
          this.licensingConditionsData.emission_date = moment(today).format('YYYY-MM-DD')
        }
      }
      const startDateNew = new Date(startDate)

      if (finalDate === 'renewal') {
        this.licensingConditionsData.renewal_date = moment(startDateNew.addDays(newValue)).format('YYYY-MM-DD')
      } else {
        this.licensingConditionsData.expiration_date = moment(startDateNew.addDays(newValue)).format('YYYY-MM-DD')
      }
    },
    calcDays(startDate, finalDate, newValue) {
      // eslint-disable-next-line
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }
      const today = new Date()
      if (!startDate) {
        if (!finalDate) {
          this.licensingConditionsData.emission_date = moment(today).format('YYYY-MM-DD')
        }
      }
      const startDateNew = new Date(startDate)

      if (finalDate === 'renewal') {
        this.licensingConditionsData.renewal_date = moment(startDateNew.addDays(-newValue + 1)).format('YYYY-MM-DD')
      } else {
        this.licensingConditionsData.expiration_date = moment(startDateNew.addDays(newValue)).format('YYYY-MM-DD')
      }
    },
    setday(renewal) {
      if (renewal) {
        this.licensingConditionsData.renewal_period = this.deadlineCountExpiration
      } else {
        this.licensingConditionsData.expiration_deadline = this.deadlineCount
      }
      console.log(this.situationOptions)
      console.log(this.licensingConditionsData.situation)
    },
    fetchLicensing(id) {
      license.getLicensingConditionsById(id).then(res => {
        this.licensingConditionsData = res.data
        this.setlicensingConditionsData(res.data)
        this.setSubtitle(this.licensingConditionsData.licensing_conditions_name)
        this.fetchEnterpriseData(this.licensingConditionsData.project.id)
      })
    },
    saveChanges(form) {
      delete form.constraint
      delete form.licenseType
      delete form.licensingAgency
      delete form.main_manager
      delete form.project

      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.createLicensingConditions) {
            license.createLicensingConditions(form).then(res => {
              this.setEdit(false)
              this.toast({
                title: 'Criado!',
                text: 'Licenciamento criado com sucesso!',
                variant: 'success',
              })
              this.$router.push(`/licenca/${res.data.id}/detalhes`)
            })
          } else {
            license.editLicensingConditions(form).then(() => {
              this.setEdit(false)
              this.toast({
                title: 'Atualizado!',
                text: 'Licenciamento atualizado com sucesso!',
                variant: 'success',
              })
              this.fetchLicensing(this.$route.params.id)
            })
          }
          this.setSubtitle(this.licensingConditionsData.licensing_conditions_name)
        }
      })
    },

    cancel() {
      this.setEdit(false)
      this.setDataEnterprise({})
      if (this.createLicensingConditions) {
        this.$router.push({ name: 'license' })
      } else {
        this.fetchLicensing(this.$route.params.id)
      }
    },
  },
}
</script>
