<template>
  <div>
    <div
      v-if="$can('update', 'licensing')"
      class="btn-actions-content d-flex"
    >
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="cancel()"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveChanges(licensingData)"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <div v-if="!apiInCall">
      <validation-observer ref="form">
        <h6>Dados do requerente</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="código"
                rules="required"
              >
                <b-form-group label="Código">
                  <b-input-group>
                    <b-form-input
                      id="codigo"
                      v-model="licensingData.licensing_code"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-group label="Nome">
                  <b-input-group>
                    <b-form-input
                      id="nome"
                      v-model="licensingData.licensing_name"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                v-slot="{ errors }"
                name="empreendimento"
                rules="required"
              >
                <b-form-group label="Empreendimento">
                  <b-input-group>
                    <b-form-select
                      id="empreendimento"
                      v-model="licensingData.project_id"
                      :options="getEnterpriseOptions"
                      :disabled="!edit"
                      @change="fetchEnterpriseData(licensingData.project_id)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group :label="enterpriseData.cnpj ? 'CNPJ' : 'CPF'">
                <b-input-group>
                  <b-form-input
                    id="cnpj"
                    v-mask="enterpriseData.cnpj ? '##.###.###/####-##' : '###.###.###-##'"
                    :value="enterpriseData ? ( enterpriseData.cnpj || enterpriseData.cpf ) : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="enterpriseData.cnpj ? 'Razão Social' : 'Nome Completo'">
                <b-input-group>
                  <b-form-input
                    id="razao-social"
                    :value="enterpriseData ? ( enterpriseData.project_name || enterpriseData.full_name ) : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-group label="Empresa Vinculada">
                <b-input-group>
                  <b-form-input
                    id="empresa-vinculada"
                    :value="enterpriseData.company ? (enterpriseData.company.nome_fantasia || enterpriseData.company.full_name) : ''"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="gestor principal"
                rules="required"
              >
                <b-form-group label="Gestor Principal">
                  <b-input-group>
                    <b-form-select
                      id="gestor-principal"
                      v-model="licensingData.main_manager_id"
                      :options="usersOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <h6>Endereço</h6>
        <b-form class="mt-3">
          <b-row>

            <b-col md="2">
              <b-form-group label="CEP">
                <b-input-group>
                  <b-form-input
                    id="cep"
                    :value="enterpriseData.zip_code"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">

              <b-form-group label="Cidade">
                <b-input-group>
                  <b-form-input
                    id="cidade"
                    :value="enterpriseData.city"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="UF">
                <b-input-group>
                  <b-form-select
                    id="uf"
                    :value="enterpriseData.state"
                    :options="ufOptions"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Bairro">
                <b-input-group>
                  <b-form-input
                    id="bairro"
                    :value="enterpriseData.neighborhood"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Endereço">
                <b-input-group>
                  <b-form-input
                    id="endereco"
                    :value="enterpriseData.address"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Número / Complemento">
                <b-input-group>
                  <b-form-input
                    id="numero"
                    :value="enterpriseData.address_number"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <h6>Dados do processo</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="órgão"
                rules="required"
              >
                <b-form-group label="Órgão">
                  <b-input-group>
                    <b-form-select
                      id="orgao"
                      v-model="licensingData.agency_id"
                      :disabled="!edit"
                      :options="licensingAgencyOptions"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="tipo de processo"
                rules="required"
              >
                <b-form-group label="Tipo de Processo">
                  <b-input-group>
                    <b-form-select
                      id="tipo-processo"
                      v-model="licensingData.process_type"
                      :options="processTypeOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="tipo de licença"
                rules="required"
              >
                <b-form-group label="Tipo de Licença">
                  <b-input-group>
                    <b-form-select
                      id="tipo-licenca"
                      v-model="licensingData.license_type_id"
                      :options="licenseTypeOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group label="Número de Processo">
                <b-input-group>
                  <b-form-input
                    id="numero-processo"
                    v-model="licensingData.process_number"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data de Abertura">
                <b-input-group>
                  <b-form-input
                    id="data-abertura"
                    v-model="licensingData.opening_date"
                    type="date"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="fase"
                rules="required"
              >
                <b-form-group label="Fase">
                  <b-input-group>
                    <b-form-select
                      id="fase"
                      v-model="licensingData.step"
                      :options="stepOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-group label="Licença Anterior">
                <b-input-group>
                  <b-form-input
                    id="licenca-anterior"
                    v-model="licensingData.previous_license"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Processo Anterior">
                <b-input-group>
                  <b-form-input
                    id="processo-anterior"
                    v-model="licensingData.previous_process"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <h6>Gestão de prazo</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="data de início"
                rules="required"
              >
                <b-form-group label="Data de Início">
                  <b-input-group>
                    <b-form-input
                      id="data-inicio"
                      v-model="licensingData.start_date"
                      :disabled="!edit"
                      type="date"
                      @change="setday()"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data de Conclusão">
                <b-input-group>
                  <b-form-input
                    id="data-conclusao"
                    v-model="licensingData.conclusion_date"
                    type="date"
                    :min="licensingData.start_date"
                    :disabled="!edit"
                    @change="setday()"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="prazo de conclusão"
                rules="required|positive"
              >
                <b-form-group label="Prazo de Conclusão">
                  <b-input-group>
                    <b-form-input
                      id="prazo-conclusao"
                      v-model.number="licensingData.conclusion_deadline"
                      :disabled="!edit"
                      type="number"
                      @input="calcDays(licensingData.conclusion_deadline)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group label="Contagem Regressiva">
                <b-input-group>
                  <b-form-input
                    id="contagem-regressiva"
                    :value="regressiveCount(licensingData.conclusion_date)"
                    type="number"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!createLicensing">
            <b-col md="2">
              <b-form-group label="Progresso">
                <b-input-group>
                  <b-form-input
                    id="progresso"
                    v-model="licensingData.progress"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <b-spinner
      v-else
      variant="primary"
      class="general-data-spinner"
    />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import VSIcons from '@/assets/icons/VSIcons.vue'
import licensing from '@/services/licensing'
import { required } from '@/@core/utils/validations/validations'
import { formatDateTime } from '@/@core/utils/filter'

export default {
  components: {
    BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, BFormSelect, VSIcons, BSpinner,
  },
  filters: {
    formatDateTime,
  },
  data() {
    return {
      required,
      createLicensing: false,
      companyId: null,
      licensingData: {
        licensing_code: '',
        licensing_name: '',
        start_date: '',
        project_id: null,
        agency_id: null,
        process_type: '',
        license_type_id: null,
        process_number: '',
        opening_date: '',
        step: '',
        previous_license: '',
        previous_process: '',
        conclusion_date: '',
        conclusion_deadline: 0,
        progress: 0,
        project: {
          id: null,
          fantasy_name: '',
          project_name: '',
          company: {
            id: null,
            nome_fantasia: '',
          },
          zip_code: null,
          address: null,
          number_address: null,
          neighborhood: null,
          city: null,
          state: null,
          createdAd: '',
        },
        licensingAgency: {
          id: null,
          name: '',
        },
        licenseType: {
          id: null,
          subject: '',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      edit: 'app/getItemTableEdit',
      apiInCall: 'app/getApiInCall',
      sectorOptions: 'sector/getSectorsOptions',
      companiesOptions: 'company/getCompaniesOptions',
      ufOptions: 'app/getUfOptions',
      stepOptions: 'app/getStepOptions',
      deadlineOptions: 'app/getDeadlineOptions',
      getEnterpriseOptions: 'enterprise/getEnterpriseOptions',
      licensingAgencyOptions: 'publicAgencies/getLicensingAgencyOptions',
      getLicenseTypesOptionsByPublicAgency: 'licenseType/getLicenseTypesOptionsByPublicAgency',
      enterpriseData: 'enterprise/getEnterpriseData',
      processTypeOptions: 'app/getProcessTypeOptions',
      usersOptions: 'users/getUsersOptions',
    }),
    deadlineCount: {
      get() {
        const diference = new Date(this.licensingData.conclusion_date) - new Date(this.licensingData.start_date)
        const days = (diference / (60 * 60 * 24 * 1000))
        return days || 0
      },
    },
    enterpriseQuantity() {
      return this.licensingData.projects.length
    },
    licenseTypeOptions() {
      return this.getLicenseTypesOptionsByPublicAgency(this.licensingData.agency_id)
    },
  },
  created() {
    if (this.verifyRouteAddNewItem()) {
      this.createLicensing = true
      this.setSubtitle('')
      this.setDataEnterprise({})
    } else {
      this.fetchLicensing(this.$route.params.id)
    }
    this.fetchCompanies()
    this.fetchEnterprises()
    this.fetchLicensingAgencies()
    this.fetchLincenseTypes()
    this.fetchUsers()
  },
  mounted() {
    this.setDataEnterprise({})
  },
  methods: {
    ...mapMutations({
      setEdit: 'app/SET_ITEM_TABLE_EDIT',
      setSubtitle: 'app/SET_SUBTITLE',
      setLicensingData: 'licensing/SET_LICENSING_DATA',
      setDataEnterprise: 'enterprise/SET_ENTERPRISE_DATA',
    }),
    ...mapActions({
      fetchCompanies: 'company/fetchCompanies',
      fetchEnterprises: 'enterprise/fetchEnterprises',
      fetchLicensingAgencies: 'publicAgencies/fetchLicensingAgencies',
      fetchLincenseTypes: 'licenseType/fetchLincenseTypes',
      fetchEnterpriseData: 'enterprise/fetchEnterpriseById',
      fetchUsers: 'users/fetchUsers',
    }),

    calcDays(newValue) {
      // eslint-disable-next-line
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }
      const today = new Date()
      if (!this.licensingData.start_date) this.licensingData.start_date = moment(today).format('YYYY-MM-DD')
      const startDate = new Date(this.licensingData.start_date)

      this.licensingData.conclusion_date = moment(startDate.addDays(newValue)).format('YYYY-MM-DD')
    },
    setday() {
      this.licensingData.conclusion_deadline = this.deadlineCount
    },
    fetchLicensing(id) {
      licensing.getLicensingById(id).then(res => {
        this.licensingData = res.data
        this.setLicensingData(res.data)
        this.setSubtitle(this.licensingData.licensing_name)
        this.fetchEnterpriseData(this.licensingData.project.id)
      })
    },
    saveChanges(form) {
      delete form.project
      delete form.licensingAgency
      delete form.licenseType
      delete form.docsActivity
      delete form.main_manager

      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.createLicensing) {
            licensing.createLicensing(form).then(res => {
              this.setEdit(false)
              this.toast({
                title: 'Criado!',
                text: 'Licenciamento criado com sucesso!',
                variant: 'success',
              })
              this.$router.push(`/licenciamento/${res.data.id}/detalhes`)
            })
          } else {
            licensing.editLicensing(form).then(() => {
              this.setEdit(false)
              this.toast({
                title: 'Atualizado!',
                text: 'Licenciamento atualizado com sucesso!',
                variant: 'success',
              })
              this.fetchLicensing(this.$route.params.id)
            })
          }
          this.setSubtitle(this.licensingData.licensing_name)
        }
      })
    },

    cancel() {
      this.setEdit(false)
      this.setDataEnterprise({})
      if (this.createLicensing) {
        this.$router.push({ name: 'licensing' })
      } else {
        this.fetchLicensing(this.$route.params.id)
      }
    },
  },
}
</script>
