<template>
  <div class="attachments-page">

    <div
      v-if="$can('create', permissionModule )"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="openModal({action: 'create'})"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>

    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="attachments-table"
      class="details-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      small
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', permissionModule )"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('/system/accountable', 'Accountable')"
        />
      </template>
      <template
        v-if="$can('delete', permissionModule )"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/accountable', fetchTable: 'Accountable' })"
        />
      </template>
      <template #cell(actions)="row">
        <b-dropdown
          no-caret
          right
          variant="light"
          menu-class="dropdown-table-actions"
          toggle-class="button-dropdown-actions"
        >
          <template #button-content>
            <VSIcons
              name="Actions"
              width="5"
              height="22"
            />
          </template>
          <b-dropdown-item
            class="dropdown-table-item"
            @click="openModal({item: row.item, action: 'read'})"
          >
            <VSIcons
              name="Eye"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Ver
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="$can('update', permissionModule )" />
          <b-dropdown-item
            v-if="$can('update', permissionModule )"
            class="dropdown-table-item"
            @click="openModal({item: row.item, action: 'edit'})"
          >
            <VSIcons
              name="Edit"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Editar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="$can('update', permissionModule )" />
          <b-dropdown-item
            v-if="$can('update', permissionModule )"
            class="dropdown-table-item"
            @click="uploadFile(row.item)"
          >
            <VSIcons
              name="PaperClip"
              width="22"
              height="22"
              color="#7D8E9E"
            />
            <span>
              Anexar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="$can('delete', permissionModule )" />
          <b-dropdown-item
            v-if="$can('delete', permissionModule )"
            class="dropdown-table-item"
            @click="deleteItem(row.item)"
          >
            <VSIcons
              name="TrashSquare"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Excluir
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(prazo)="row">
        <b-badge variant="warning">
          <div class="d-flex align-items-center">
            <VSIcons
              name="Clock"
              width="18"
              height="18"
              color="#FECD2F"
            />
            <span class="font-12 text-light">{{ row.item.prazo }}D</span>
          </div>
        </b-badge>
      </template>
      <template #cell(anotations)="row">
        <div class="d-flex align-items-center">
          {{ row.item.anotations }}
          <VSIcons
            name="NoteFillGradient"
          />
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="attachments-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      name="UploadFile"
      :properties="properties"
    />
    <VSModal
      width="970"
      name="AddResponsables"
      :properties="properties"
    />
    <VSModal
      width="570"
      :properties="itemToDelete"
      name="DeleteOneItem"
    />
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BTable,
  BButton,
  BFormCheckbox,
  BSpinner,
  BBadge,
  BPagination,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    BButton,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    BBadge,
    VSModal,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    items: {
      type: Array,
      required: true,
    },
    module: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      properties: {},
      itemToDelete: { api: '/system/accountable', fetchTable: 'Accountable' },
      fields: [
        {
          label: '',
          key: 'select',
          tdClass: 'align-middle',
        },
        {
          label: 'Nome',
          key: 'name',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'CPF',
          key: 'cpf',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Telefone',
          key: 'telephone',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'E-mail',
          key: 'email',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Profissao',
          key: 'occupation',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Reg. Profissional',
          key: 'professional_registration',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Tipo',
          key: 'type',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle',
        },

      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall' }),
    permissionModule() {
      if (this.module) { return 'licensesconditions' }
      return 'licensingagency'
    },
  },
  methods: {
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    openModal(item) {
      this.properties = item
      this.properties.module = this.module
      this.showModal('AddResponsables')
    },
    uploadFile(row) {
      this.properties.action = 'create'
      this.properties.relations = [{ name: 'accountable_id', id: row.id }]
      if (this.module) {
        this.properties.relations.push({ name: 'licensingCondition_id', id: this.$route.params.id })
      } else {
        this.properties.relations.push({ name: 'licensing_id', id: this.$route.params.id })
      }
      this.showModal('UploadFile')
    },
  },
}
</script>
<style lang="scss">

</style>
