<template>
  <LicensingDetails />
</template>

<script>
import LicensingDetails from '@/modules/licensing/licensing-details/LicensingDetails.vue'

export default {
  components: { LicensingDetails },
}
</script>
