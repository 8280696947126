<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
      >
        <LicenseDetailsGeneralData />
      </b-tab>
      <div v-if="!verifyRouteAddNewItem()">
        <b-tab title="Condicionantes e atividades" :active="getIsCondAndActTabActive">
          <LicenseDetailsDocumentsActivities :items="documentsConditionsItems" />
        </b-tab>
        <b-tab title="Diário de Bordo">
          <LicenseDetailsLogBook
            :items="itemsLogbook"
            module="LicenseConditions"
          />
        </b-tab>
        <b-tab title="Notificações e autos">
          <NotificationsRecordsDetails
            :items="itemsRecords"
            module="LicenseConditions"
          />
        </b-tab>
        <b-tab title="Pareceres e Vistorias">
          <SurveyDetails
            :items="itemsInspects"
            module="LicenseConditions"
          />
        </b-tab>
        <b-tab title="Responsáveis">
          <ResponsablesDetails
            :items="accountableItems"
            module="LicenseConditions"
          />
        </b-tab>
        <b-tab>
          <template #title>
            Anexos
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ attachmentsLicense.length }}
            </b-badge>
          </template>
          <LicenseAttachments
            :items="attachmentsLicense"
            relation="licensingCondition_id"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BBadge } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import NotificationsRecordsDetails from '@/components/notifications-records-details/NotificationsRecordsDetails.vue'
import LicenseDetailsGeneralData from '@/modules/license/license-details/LicenseDetailsGeneralData.vue'
import LicenseDetailsDocumentsActivities from '@/modules/license/license-details/LicenseDetailsConditionsActivities.vue'
import LicenseDetailsLogBook from '@/components/logbook-details/LicenseDetailsLogBook.vue'
import ResponsablesDetails from '@/components/responsables-details/ResponsablesDetails.vue'
import SurveyDetails from '@/components/surveys-details/SurveyDetails.vue'
import LicenseAttachments from '@/components/attachments-details/DetailsAttachments.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,
    LicenseDetailsGeneralData,
    LicenseDetailsDocumentsActivities,
    LicenseDetailsLogBook,
    NotificationsRecordsDetails,
    ResponsablesDetails,
    SurveyDetails,
    LicenseAttachments,
  },
  computed: {
    ...mapGetters({
      documentsConditionsItems: 'licensingConditions/getLicensingConditionsConstraints',
      logBookitems: 'logbook/getLogbooksLicensing',
      getLogbooks: 'logbook/getLogbooks',
      getRecords: 'records/getRecords',
      getInspects: 'inspects/getInspects',
      getAccountable: 'accountable/getAccountable',
      attachments: 'upload/getUpload',
      getIsCondAndActTabActive: 'licensingConditions/getIsCondAndActTabActive',
    }),
    itemsLogbook() {
      return this.getLogbooks.filter(arr => arr.licensingCondition_id === Number(this.$route.params.id))
    },
    itemsRecords() {
      return this.getRecords.filter(arr => arr.licensingCondition_id === Number(this.$route.params.id))
    },
    itemsInspects() {
      return this.getInspects.filter(arr => arr.licensingCondition_id === Number(this.$route.params.id))
    },
    accountableItems() {
      return this.getAccountable.filter(arr => arr.licensingCondition_id === Number(this.$route.params.id))
    },
    attachmentsLicense() {
      return this.attachments.filter(arr => arr.licensingCondition_id === Number(this.$route.params.id))
    },
  },
  created() {
    this.fetchLicensingConstraints(this.$route.params.id)
    this.fetchLogbooks()
    this.fetchRecords()
    this.fetchInspects()
    this.fetchAccountable()
    this.fetchUploads()
  },
  methods: {
    ...mapActions({
      fetchLicensingConstraints: 'licensingConditions/fetchLicensingConditionsConstraints',
      fetchLogbooks: 'logbook/fetchLogbooks',
      fetchRecords: 'records/fetchRecords',
      fetchInspects: 'inspects/fetchInspects',
      fetchAccountable: 'accountable/fetchAccountable',
      fetchUploads: 'upload/fetchUploads',
    }),
  },
}
</script>
