<template>
  <div class="attachments-page">

    <div
      v-if="$can('create', 'licensing')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="openModalImport()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>

    <b-input-group
      class="input-group-merge round search-input-filter"
    >
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
      <h5
        v-if="recurrenceTitle && recurrence.length > 0"
        class="mt-3"
      >
        Recorrências:
        <b-button
          class="custom-btn"
          variant="link"
          @click="listConditions()"
        >
          {{ recurrenceTitle }}
        </b-button>
      </h5>
    <b-table
      id="attachments-table"
      class="details-table mt-4"
      :items="recurrence.length > 0 ? recurrence : items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :per-page="perPage"
      :current-page="currentPage"
      small
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'licensing')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="recurrence.length > 0 ? toggleAll(`/system/licensing-conditions/constraints`, 'LicensingConditionsConstraintsRecurrence') : toggleAll('/system/licensing-conditions/constraints', 'LicensingConditionsConstraints')"
        />
      </template>
      <template #cell(accountable)="row">
        {{ row.item.accountable || 'Não informado' }}
      </template>
      <template #cell(protocol)="row">
        {{ row.item.protocol ? row.item.protocol_date : 'Não' | formatLocalDate }}
      </template>
      <template
        v-if="$can('delete', 'licensing')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: apiPath })"
        />
      </template>
      <template
        v-if="$can('delete', 'licensing')"
        #cell(actions)="row"
      >
        <b-dropdown
          v-if="$can('read', 'licensing')"
          no-caret
          right
          variant="light"
          menu-class="dropdown-table-actions"
          toggle-class="button-dropdown-actions"
        >
          <template #button-content>
            <VSIcons
              name="Actions"
              width="5"
              height="22"
            />
          </template>
          <b-dropdown-item
            v-if="$can('read', 'licensing')"
            class="dropdown-table-item"
            @click="openModal({item: row.item, action: 'read'})"
          >
            <VSIcons
              name="Eye"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Ver
            </span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            v-if="$can('update', 'licensing')"
            class="dropdown-table-item"
            @click="openModal({item: row.item, action: 'edit'})"
          >
            <VSIcons
              name="Edit"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Editar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider
            v-if="(recurrence.length === 0) && (row.item.recurrence)"
          />
          <b-dropdown-item
            v-if="$can('update', 'licensing') && (recurrence.length === 0) && (row.item.recurrence)"
            class="dropdown-table-item"
            @click="openRecurrence(row.item)"
          >
            <VSIcons
              name="ReceiptEdit"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Recorrências
            </span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            v-if="$can('update', 'licensing')"
            class="dropdown-table-item"
            @click="uploadFile(row.item)"
          >
            <VSIcons
              name="PaperClip"
              width="22"
              height="22"
              color="#7D8E9E"
            />
            <span>
              Anexar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            v-if="$can('delete', 'licensing')"
            class="dropdown-table-item"
            @click="getDataItem(row.item)"
          >
            <VSIcons
              name="TrashSquare"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Excluir
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(deadline)="row">
        <b-badge
          v-if="row.item.status === 'Concluído'"
          variant="primary"
          class="p-2"
        >
          <span class="font-12">
            {{ 'Concluído' }}
          </span>
        </b-badge>
        <b-badge
          v-else
          :variant="variantPercentageDeadlineVariant(row.item.deadline, regressiveCount(row.item.conclusion_date))"
        >
          <div class="d-flex align-items-center">
            <VSIcons
              name="Clock"
              width="18"
              height="18"
              :color="variantPercentageDeadlineColor(row.item.deadline, regressiveCount(row.item.conclusion_date))"
            />
            <span class="font-12 text-light">{{ regressiveCount(row.item.conclusion_date) }}D</span>
          </div>
        </b-badge>
      </template>
      <template #cell(supplier)="row">
        {{ row.item.supplier ? (row.item.supplier.fantasy_name || row.item.supplier.full_name) : 'Não informado' }}
      </template>
      <template #cell(notification)="row">
        {{ row.item.notification[0] ? row.item.notification[0].record_number : '' }}
      </template>
      <template #cell(anotations)="row">
        <div
          v-b-toggle.sidebar-right
          class="d-flex align-items-center"
          @click="getRowAnotation(row.item)"
        >
          {{ row.item.annotations ? row.item.annotations.length : '' }}
          <VSIcons
            name="NoteFillGradient"
          />
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="attachments-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      name="UploadFile"
      :properties="properties"
    />
    <VSModal
      width="970"
      name="AddNewDocumentsActivies"
      :properties="propsModal"
    />
    <VSModal
      width="570"
      :properties="propertiesDeleteModal"
      name="DeleteOneItem"
    />
    <Anotations
      :item="itemAnotation"
      module="LicensingConditions"
    />
    <VSModal
      width="500"
      name="Import"
      :draggable="false"
    />
    <VSModal
      width="900"
      name="LicenseDetailsConditionsActivitiesRecurrence"
      :items="recurrence"
      :draggable="false"
    />
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BTable,
  BButton,
  BFormCheckbox,
  BSpinner,
  BBadge,
  BPagination,
  VBToggle,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import {
  formatBoolean, formatLocalDate, variantPercentageDeadlineColor, variantPercentageDeadlineVariant,
} from '@core/utils/filter'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'
import Anotations from '@/components/annotations/AnnotationSidebar.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    BButton,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    BBadge,
    VSModal,
    Anotations,
  },
  filters: {
    formatBoolean,
    formatLocalDate,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      properties: { action: '' },
      itemAnotation: {},
      recurrence: [],
      idRecurrence: '',
      recurrenceTitle: '',
      apiPath: '/system/licensing-conditions/constraints',
      propsModal: {},
      propertiesDeleteModal: {
        item: {
          id: null,
        },
        api: '/system/licensing-conditions/constraints',
        fetch: 'LicensingConditionsConstraints',
      },
      fields: [
        {
          label: '',
          key: 'select',
          tdClass: 'align-middle',
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Título',
          key: 'title',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Descrição',
          key: 'description',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Responsável',
          key: 'accountable',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Prazo',
          key: 'deadline',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Recorrência',
          key: 'recurrence',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Notificação',
          key: 'notification',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Fornecedor',
          key: 'supplier',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Protocolo',
          key: 'protocol',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: '',
          key: 'anotations',
          tdClass: 'align-middle',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      apiInCall: 'app/getApiInCall',
      getRecurrence: 'licensingConditions/getLicensingConditionsConstraintsRecurrence',
    }),
  },
  methods: {
    ...mapActions({
      fetchLicensingConstraints: 'licensingConditions/fetchLicensingConditionsConstraintsRecurrence',
    }),
    variantPercentageDeadlineColor,
    variantPercentageDeadlineVariant,
    getDataItem(item) {
      this.propertiesDeleteModal.item.id = item.id
      this.showModal('DeleteOneItem')
    },
    openModal(item) {
      this.propsModal = item
      this.propsModal.module = 'LicensinConditions'
      this.showModal('AddNewDocumentsActivies')
    },
    async openRecurrence(item) {
      try {
        await this.fetchLicensingConstraints(item.id)
        this.recurrence = this.getRecurrence
        this.idRecurrence = item.id
        this.recurrenceTitle = item.title
        if (this.getRecurrence.length === 0) {
          this.toast({
            title: 'Erro',
            text: 'Essa condicionante não possui recorrências',
            variant: 'erro',
          })
        }
      } catch (error) {
        console.error('Erro ao buscar as condições de licenciamento:', error)
      }
    },
    listConditions() {
      this.recurrence = []
    },
    openModalImport() {
      this.propsModal.action = 'create'
      this.propsModal.module = 'LicensinConditions'
      this.showModal('Import')
    },
    getRowAnotation(row) {
      this.itemAnotation = row
    },
    uploadFile(row) {
      this.properties.action = 'create'
      this.properties.relations = [{ name: 'constraint_id', id: row.id }]
      this.properties.relations.push({ name: 'licensingCondition_id', id: this.$route.params.id })
      this.showModal('UploadFile')
    },
  },
}
</script>
<style lang="scss" scoped>
  .rotateIcon {
      transform: rotate(180deg);
    }

  .custom-btn {
    color: #2a2e30;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.25rem;
    padding: 0;
    border: none;
    height: 11px;
    line-height: 1px;
  }
  .custom-btn:focus {
    box-shadow: none;
  }
</style>
