<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
      >
        <LicensingGeneralData />
      </b-tab>
      <div v-if="!verifyRouteAddNewItem()">
        <b-tab title="Documentos e atividades" :active="getIsDocsAndActTabActive">
          <LicensingDetailsDocumentsActivities :items="documentsActiviesItems" />
        </b-tab>
        <b-tab title="Diário de bordo">
          <LicensingLogbook :items="itemsLogbook" />
        </b-tab>
        <b-tab title="Notificações e autos">
          <LicensingNotifications
            :items="itemsRecords"
          />
        </b-tab>
        <b-tab title="Pareceres e vistorias">
          <LicensingSurveys
            :items="itemsInspects"
          />
        </b-tab>
        <b-tab title="Responsáveis">
          <LicensingResponsables
            :items="accountableItems"
          />
        </b-tab>
        <b-tab>
          <template #title>
            Anexos
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ attachmentsLicensing.length }}
            </b-badge>
          </template>
          <LicensingAttachments
            :items="attachmentsLicensing"
            relation="licensing_id"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BBadge } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import LicensingGeneralData from './LicensingDetailsGeneralData.vue'
import LicensingDetailsDocumentsActivities from '@/components/documents-activies/DocumentsActivities.vue'
import LicensingLogbook from '@/components/logbook-details/LicenseDetailsLogBook.vue'
import LicensingNotifications from '@/components/notifications-records-details/NotificationsRecordsDetails.vue'
import LicensingSurveys from '@/components/surveys-details/SurveyDetails.vue'
import LicensingResponsables from '@/components/responsables-details/ResponsablesDetails.vue'
import LicensingAttachments from '@/components/attachments-details/DetailsAttachments.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,

    LicensingGeneralData,
    LicensingDetailsDocumentsActivities,
    LicensingLogbook,
    LicensingNotifications,
    LicensingSurveys,
    LicensingResponsables,
    LicensingAttachments,
  },
  computed: {
    ...mapGetters({
      documentsActiviesItems: 'licensing/getLicensingDocuments',
      logBookitems: 'logbook/getLogbooksLicensing',
      getLogbooks: 'logbook/getLogbooks',
      getRecords: 'records/getRecords',
      getInspects: 'inspects/getInspects',
      getAccountable: 'accountable/getAccountable',
      attachments: 'upload/getUpload',
      getIsDocsAndActTabActive: 'licensing/getIsDocsAndActTabActive',
    }),
    itemsLogbook() {
      return this.getLogbooks.filter(arr => arr.licensing_id === Number(this.$route.params.id))
    },
    itemsRecords() {
      return this.getRecords.filter(arr => arr.licensing_id === Number(this.$route.params.id))
    },
    itemsInspects() {
      return this.getInspects.filter(arr => arr.licensing_id === Number(this.$route.params.id))
    },
    accountableItems() {
      return this.getAccountable.filter(arr => arr.licensing_id === Number(this.$route.params.id))
    },
    attachmentsLicensing() {
      return this.attachments.filter(arr => arr.licensing_id === Number(this.$route.params.id))
    },
  },
  created() {
    this.fetchLicensingDocuments(this.$route.params.id)
    this.fetchLogbooks()
    this.fetchRecords()
    this.fetchInspects()
    this.fetchAccountable()
    this.fetchUploads()
  },
  methods: {
    ...mapActions({
      fetchLicensingDocuments: 'licensing/fetchLicensingDocuments',
      fetchLogbooks: 'logbook/fetchLogbooks',
      fetchRecords: 'records/fetchRecords',
      fetchInspects: 'inspects/fetchInspects',
      fetchAccountable: 'accountable/fetchAccountable',
      fetchUploads: 'upload/fetchUploads',
    }),
  },
}
</script>
