<template>
  <modal
    class="annotations-modal"
    :draggable="true"
    name="AnnotationModal"
    height="270"
    width="350"
    :click-to-close="false"
    @before-open="isEdit()"
    @before-close="cleanAnnotation()"
  >
    <div
      class="content-annotations-modal"
      :class="item.color"
    >
      <div class="position-relative">
        <!-- <template #top-right>
      </template> -->
        <div class="close-button-wrapper">
          <div
            v-if="!savingAnnotation"
            @click="$modal.hide('AnnotationModal')"
          >
            <VSIcons
              class="close-modal"
              name="close"
              color="#FFF"
            />
          </div>
          <b-spinner
            v-else
            class="spinner-loading"
            small
            variant="light"
          />
        </div>
        <b-badge
          class="anotation-badge"
          :class="item.color"
        >
          {{ item.createdAt | formatDate }}
        </b-badge>
        <b-form-textarea
          id="anotation-text"
          v-model="annotation.note"
          class="mt-2"
          cols="40"
          rows="10"
          max-rows="10"
          @input="saveAnnotation()"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import {
  BBadge,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import { mapActions } from 'vuex'
import _ from 'lodash'
import VSIcons from '@/assets/icons/VSIcons.vue'
import annotation from '@/services/annotation'

export default {
  components: {
    BBadge,
    BFormTextarea,
    BSpinner,
    VSIcons,
  },
  filters: {
    formatDate,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      annotation: {
        note: '',
      },
      savingAnnotation: false,
    }
  },
  methods: {
    ...mapActions({ fetchAnnotationsConstraints: 'annotations/fetchAnnotationsConstraints', fetchAnnotationsDocuments: 'annotations/fetchAnnotationsDocuments' }),

    fetchAnnotations() {
      if (this.module) {
        this.fetchAnnotationsConstraints(this.item.constraint_id)
      } else {
        this.fetchAnnotationsDocuments(this.item.docsActivity_id)
      }
    },

    cleanAnnotation() {
      this.annotation.note = ''
    },
    isEdit() {
      if (this.edit) {
        this.annotation.note = this.item.note
      }
    },
    saveApi() {
      this.savingAnnotation = true
      this.annotation.id = this.item.id
      annotation.editAnnotation(this.annotation).then(() => {
        this.fetchAnnotations()
        this.savingAnnotation = false
      })
    },
    debounceSave() {
      this.savingAnnotation()
    },
    saveAnnotation: _.debounce(function () {
      this.saveApi()
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.close-modal {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(5px) translateY(-20px);
    color:  #7D8E9E;
    z-index: 20;
    cursor: pointer;
}
.spinner-loading {
  position: absolute;
  top: -13px;
  right: 0;
}
.close-button-wrapper {
  position: absolute;
  top: -22px;
  right: 0;
}

</style>
