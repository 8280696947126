<template>
  <div
    class="anotation-card overflow cursor-pointer"
    :class="item.color"
    @click="$emit('click-card')"
  >
    <b-badge
      class="anotation-badge"
      :class="item.color"
    >
      {{ item.createdAt | formatDate }}
    </b-badge>
    <p class="anotation-text">
      {{ item.note }}
    </p>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BBadge,
  },
  filters: {
    formatDate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .pink {
    border-color: #FE63B7;
    .anotation-badge {
      background-color: #FE63B7;
    }
  }
  .blue {
    border-color: #52C9E9;
    .anotation-badge {
      background-color: #52C9E9;
    }
  }
  .orange {
    border-color: #EE665E;
    .anotation-badge {
      background-color: #EE665E;
    }
  }
  .purple {
    border-color: #AF5CF6;
    .anotation-badge {
      background-color: #AF5CF6;
    }
  }
  .green {
    border-color: #5CB973;
    .anotation-badge {
      background-color: #5CB973;
    }
  }
</style>
