<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      right
      shadow
      bg-variant="dark"
      text-variant="light"
      sidebar-class="pr-2 pl-2"
      body-class="pr-4 pl-4"
      backdrop-variant=""
      @shown="fetchAnnotations()"
      @hidden="fetchTable()"
    >
      <div class="fixed">
        <div class="mt-3 mb-3 header-anotations">
          <div>
            <h6 class="anotations-title">
              Minhas anotações
            </h6>
            <span class="anotations-subtitle">{{ item.title }} </span>
          </div>
          <b-button
            variant="primary"
            class="d-flex align-items-center"
            @click="createAnnotation()"
          >
            <VSIcons
              name="AddSquare"
              class="mr-1"
            /> Adicionar
          </b-button>
        </div>
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <VSIcons
                name="Search"
                color="#7D8E9E"
              />
            </b-input-group-prepend>
            <b-form-input placeholder="Search" />
          </b-input-group>
        </b-form-group>
      </div>
      <div class="anotations-cards">
        <AnnotationCard
          v-for="itemCard in annotations"
          :key="itemCard.id"
          :item="itemCard"
          @click-card="editAnnotation(itemCard)"
        />
      </div>
    </b-sidebar>
    <AnnotationCreateModal
      :item="itemModal"
      :module="module"
    />
  </div>
</template>

<script>
import {
  BSidebar, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BButton,
} from 'bootstrap-vue'
import { formatLocalDate } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import AnnotationCard from './AnnotationCard.vue'
import AnnotationCreateModal from './AnnotationCreateModal.vue'
import annotation from '@/services/annotation'

export default {
  components: {
    BSidebar,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    VSIcons,
    AnnotationCard,
    AnnotationCreateModal,
  },
  filters: {
    formatLocalDate,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    module: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      annotation: { note: '' },
      itemModal: { note: '' },
      colors: [
        { name: 'pink', color: '#FE63B7' },
        { name: 'purple', color: '#AF5CF6' },
        { name: 'orange', color: '#EE665E' },
        { name: 'green', color: '#5CB973' },
        { name: 'blue', color: '#52C9E9' },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall', annotations: 'annotations/getAnnotations' }),
  },
  methods: {
    ...mapActions({
      fetchAnnotationsConstraints: 'annotations/fetchAnnotationsConstraints',
      fetchAnnotationsDocuments: 'annotations/fetchAnnotationsDocuments',
      fetchLicensingDocuments: 'licensing/fetchLicensingDocuments',
      documentsConditionsItems: 'licensingConditions/getLicensingConditionsConstraints',
    }),
    createAnnotation() {
      this.annotation.color = this.colors[this.getRandomInt()].name
      if (this.module) { this.annotation.constraint_id = Number(this.item.id) } else { this.annotation.docsActivity_id = Number(this.item.id) }

      annotation.createAnnotation(this.annotation).then(res => {
        this.itemModal = res.data
        this.fetchAnnotations()
        this.$modal.show('AnnotationModal')
      })
    },
    fetchAnnotations() {
      if (this.module) {
        this.fetchAnnotationsConstraints(this.item.id)
      } else {
        this.fetchAnnotationsDocuments(this.item.id)
      }
    },
    fetchTable() {
      if (this.module) {
        this.fetchLicensingConstraints(this.$route.params.id)
      }
      this.fetchLicensingDocuments(this.$route.params.id)
    },
    editAnnotation(itemCard) {
      this.itemModal = itemCard
      this.$modal.show('AnnotationModal')
    },
    getRandomInt() {
      let min = 1
      let max = 5
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min)) + min
    },
  },

}
</script>

<style>

</style>
