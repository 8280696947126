<template>
  <div>
    <LicenseDetails />
  </div>
</template>
<script>
import LicenseDetails from '@/modules/license/license-details/LicenseDetails.vue'

export default {
  components: {
    LicenseDetails,
  },
}
</script>

<style>

</style>
